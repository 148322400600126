




























































































































































































































































































































































import Component from 'vue-class-component'
import ExpandCollapse from "@/components/animations/ExpandCollapse.vue";
import SygniTable from "@/components/table/SygniTable.vue";
import SygniModal from "@/components/layout/SygniModal.vue";
import SygniSelect from "@/components/inputs/SygniSelect.vue";
import SygniCheckbox from "@/components/inputs/SygniCheckbox.vue";
import SygniTextArea from "@/components/inputs/SygniTextArea.vue";
import UserPresentation from "@/components/UserPresentation.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import SygniSquareButton from "@/components/buttons/SygniSquareButton.vue";
import SygniRectButton from "@/components/buttons/SygniRectButton.vue";
import SygniArrowButton from "@/components/buttons/SygniArrowButton.vue";
import {BSpinner, BTable, BvTableField} from "bootstrap-vue";
import SygniCircleButton from "@/components/buttons/SygniCircleStatusButton.vue";
import InvestorsInnerTable from "@/modules/genprox/modules/fund/modules/capital-rise/components/InvestorsInnerTable.vue";
import { CheckboxOption } from '@/store/types';
import PreviewDoc from './PreviewDoc.vue';
import { BACKEND_BASE_URL} from "@/shared/consts";
import Utils from "@/modules/shared/utils/utils";
import { Watch } from 'vue-property-decorator';
import { required, requiredIf } from 'vuelidate/lib/validators';
import { TableQuery } from '@/modules/shared/utils/TableQuery';
import SygniPagination from '@/components/table/SygniPagination.vue';
import SygniFlagPicker from '@/components/inputs/SygniFlagPicker.vue';
import SygniRadio from '@/components/inputs/SygniRadio.vue';
import SygniInput from '@/components/inputs/SygniInput.vue';
import SygniLoader from '@/components/layout/SygniLoader.vue';
import SygniDatePicker from '@/components/inputs/SygniDatePicker.vue';
import breakpoints from '@/plugins/breakpoints';
import Vue from 'vue';
import { DocumentItem } from '../store/types';
import { mapGetters } from 'vuex';
import { Dictionaries } from '@/modules/accounting/store/types';
import { create, all } from 'mathjs'
import _ from 'lodash';

const math = create(all);

Component.registerHooks(['validations'])
@Component({
  components: {
    SygniCircleButton,
    InvestorsInnerTable, SygniArrowButton, SygniRoundedButton, UserPresentation, ExpandCollapse, BSpinner, SygniSelect, SygniCheckbox, SygniTextArea, SygniSquareButton, SygniRectButton, PreviewDoc, SygniModal, SygniFlagPicker, SygniRadio, SygniInput, SygniDatePicker, SygniLoader },
  computed: {
    ...mapGetters('accounting', {
      dictionaries: 'getDictionaries',
    })
  },
})
export default class AccountingTable extends SygniTable<any> {
  math: any = math;
  isSepa: boolean = false;
  dictionaries!: Dictionaries;
  bulkRows: Array<CheckboxOption> = [];
  selectAllRowsBoolean: boolean | null = false;
  bulkOptionsMarginTop: number = 0;
  showBankAccountModal: boolean = false;
  isPaymentPackageLoading: boolean = false;
  selectedBankAccount: any = null;
  paymentForSingleDocument: any = false;
  showRejectionModal: boolean = false;
  isRejectionModalLoading: boolean = false;
  useCustomPaymentDate: boolean = false;
  customPaymentDate: string = '';
  customPackageName: string = '';
  rejectionNote: string = '';
  refresh: any = null;
  bulkPaymentStatus: string = '';
  bulkPaymentStatusPlaceholder: string = 'Select option';
  isAccountingDateLoading: boolean = false;
  isPaymentStatusLoading: boolean = false;
  isCsvExporting: boolean = false;
  accountingDateOpened: boolean = false;
  accountingDate: string = '';
  scrollPosition: number = 0;
  breakpoints = breakpoints;
  showBulkOptions: boolean = false;

  tableFields: (BvTableField & {borderless?: Boolean} & { key: string })[] = [
    { key: 'selected', sortable: false, borderless: true, label: ''},
    { key: 'documentNumber', sortable: true, class: 'text-left', label: 'Doc. No.'},
    { key: 'fileName', class: 'hide text-left', sortable: true, borderless: true, label: 'File Name' },
    { key: 'accountingId', class: 'hide text-left', sortable: true, borderless: true, label: 'Accounting ID' },
    { key: 'counterPartyName', sortable: true, borderless: true, class: 'text-left', label: 'Counterparty Name' },
    { key: 'additionDate', class: 'hide text-left', sortable: true, borderless: true, label: 'Addition Date'},
    { key: 'saleDate', class: 'hide text-left', sortable: true, borderless: true, label: 'Sale Date' },
    { key: 'paymentDate', class: 'hide text-left', sortable: true, borderless: true, label: 'Pmt. Date' },
    { key: 'paymentStatus', class: 'hide text-left', sortable: true, borderless: true, label: 'Pmt. Status' },
    { key: 'grossAmount', class: 'hide text-right', sortable: true, borderless: true, label: 'Gross' },
    { key: 'currency', class: 'hide text-left', sortable: true, label: 'Ccy.' },
    { key: 'flag', class: 'hide', sortable: false, label: 'Flag' },
    { key: 'status', sortable: true, label: 'Status' },
    { key: 'actions', sortable: false, label: 'Actions' },
  ];

  closeBulkOptions() {
    this.showBulkOptions = false
  }

  toggleActionsOpened(rowData: any) {
    this.$nextTick(() => {
      if(rowData.item.actionsOpened) {
        this.$set(rowData.item, 'actionsOpened', !rowData.item.actionsOpened);
      } else {
        this.$set(rowData.item, 'actionsOpened', true);
      }
    })
  }

  getTooltipStatusMessage(item: any) {
    if(item.status == 'rejected') return item?.note ? item.note : '';

    return item.status == 'failed' ? item.errorMessage : '';
  }

  closeRejectionModal() {
    this.showRejectionModal = false;
    this.rejectionNote = '';
  }

  enableRejectionModal() {
    this.showRejectionModal = true;
  }

  getRowDataActionItems(items: Array<string>) {
    const totalItems = [...items, 'download'];

    return totalItems;
  }

  rowHasBookActions(items: string[]) {
    return items.includes('book') || items.includes('book-readonly') ? true : false;
  }

  checkShouldSend(item: any) {
    return Utils.shouldSend(item, this.contextData, this.activeUserData)
  }

  openAccountingDateModal() {
    this.accountingDateOpened = true;
  }

  closeAccountingDateModal() {
    this.accountingDateOpened = false;
  }

  getErrors(item: any) {
    if(item.errors && item.errors.length) {
      return item.errors.join(', ');
    }

    return '';
  }

  get shouldSend() {
    return Utils.shouldSend(this.activeDocument, this.contextData, this.activeUserData)
  }

  get contextData() {
    return this.$store.getters['accounting/getContextData'];
  }

  get actionItems() {
    let actions: Array<string> = [];

    this.items.forEach((item: DocumentItem) => {
      actions.push(...item.actions);
    });

    if(actions.includes('book') && actions.includes('book-readonly')) {
      actions = actions.filter((action: string) => {
        return action !== 'book-readonly';
      });
    }

    actions = actions.filter((action: string) => {
      return !(action == 'edit-payment-status' || action == 'synchronize-counterparty' || action == 'cancel' || action == 'edit-accounting-date' || action == 'preview' || action == 'approve' || action == 'reject' || action == 'add-attachment' || action == 'delete-attachment' || action == 'update-description' || action == 'send-to-approval-readonly' || action == 'change-cash-bank-report-created-flag');
    });

    actions = [...new Set(actions), 'download'];

    if(actions.indexOf('delete') != -1) {
      actions.push(actions.splice(actions.indexOf('delete'), 1)[0]);
    }

    return actions.sort((a: any) => a === 'edit' ? -1 : 0);
  }

  get formattedActionItems() {
    return this.actionItems.map((value: string, index: number) => {
      return {
        id: index + 1,
        key: value,
      }
    })
  }

  get actionItemsLength() {
    return this.actionItems.length;
  }

  get defaultPaymentPackageName() {
    const bankAccount = this.selectedBankAccount ? this.selectedBankAccount : 'xxxxx';
    const date = new Date();
    return `Payment package from ${bankAccount}, ${date.getFullYear()}-${Utils.pad(date.getMonth() + 1, 2)}-${Utils.pad(date.getDate() + 1, 2)} ${Utils.pad(date.getHours(), 2)}:${Utils.pad(date.getMinutes(), 2)}:${Utils.pad(date.getSeconds(), 2)}`;
  }

  onFiltersChange(filtersQuery?: string): void {
    this.$store.commit(this.setTableBusyMutation, true);
    this.$store.commit('accounting/setDocumentsTableFiltersQuery', filtersQuery);
    this.paginationInstance.$emit('changePage', 1);
    this.$nextTick(() => {
      const sign: string = this.sortDesc ? '-' : '';
      let sortBy: string = '';

      switch(this.sortBy) {
        default:
          sortBy = this.sortBy;
          break;
      }

      this.sortingQuery = {
        name: sortBy,
        order: sign,
      }

      this.$store.commit('accounting/setDocumentsTableSortingQuery', this.sortingQuery);
      this.getItems();
    });
  }

  onSortChange(): void {
    this.$store.commit(this.setTableBusyMutation, true);
    this.paginationInstance.$emit('changePage', 1);
    this.$nextTick(() => {
      const sign: string = this.sortDesc ? '-' : '';
      let sortBy: string = '';

      switch(this.sortBy) {
        default:
          sortBy = this.sortBy;
          break;
      }

      this.sortingQuery = {
        name: sortBy,
        order: sign,
      }

      this.$store.commit('accounting/setDocumentsTableSortingQuery', this.sortingQuery);
      this.getItems();
    });
  }

  refreshTable(): void {
    this.$store.commit(this.setTableBusyMutation, true);
    this.$nextTick(() => {
      this.getItems();
    })
  }

  toggleAllRowsAction() {
    if(this.selectAllRowsBoolean === null) return;
    this.selectAllRowsBoolean = !this.selectAllRowsBoolean;

    if(this.selectAllRowsBoolean) {
      const selectedRowEl = (this.$refs.accountingTable as BTable).$el.querySelector('.table tbody tr:nth-of-type(1)');
      this.bulkOptionsMarginTop = (selectedRowEl as HTMLDivElement).offsetTop + (selectedRowEl as HTMLDivElement).offsetHeight - 20;
      (this.$refs.accountingTable as BTable).selectAllRows();
    } else {
      (this.$refs.accountingTable as BTable).clearSelected();
    }

    this.bulkRows.forEach(row => {
      row.value = this.selectAllRowsBoolean;
    });
  }

  selectRowEl(index: number) {
    if(this.$refs.accountingTable == undefined) return;

    this.deselectRowEl();
    const activeRow = ((this.$refs.accountingTable as AccountingTable).$el as HTMLElement).querySelector(`tbody tr[aria-rowindex="${index + 1}"]`);
    this.$scrollTo(activeRow);
    activeRow.classList.add('active');
  }

  deselectRowEl() {
    if(this.$refs.accountingTable == undefined) return;

    const rows = ((this.$refs.accountingTable as AccountingTable).$el as HTMLElement).querySelectorAll(`tbody tr`);
    rows.forEach(row => row.classList.remove('active'));
  }

  get accountingTableQuery() {
    return this.$store.getters['accounting/getDocumentsTableQuery'];
  }

  selectRowDoc(item: any, index: number, add: boolean = false) {
    const offset = this.accountingTableQuery.offset;
    this.$store.commit('accounting/setSelectedDocument', item);
    if(add) {
      this.$store.commit('accounting/setDocumentIndexes', index);
    } else {
      this.$store.commit('accounting/setDocumentIndexes', index + offset);
    }

    if(this.selectedDocument) {
      this.selectRowEl(index)
    } else {
      this.$store.commit('accounting/clearDocumentIndexes');
      this.deselectRowEl();
    }
  }

  onRowClicked(item: any, index: number) {    
    this.$store.commit('accounting/setDocumentIndexes', index);
    
    if(item?.id) {
      this.$router.push(`${this.$route.path}/${item.id}`);
    }
  }

  toggleTableRow(item: any, index: number) {
    const selectedRowEl = (this.$refs.accountingTable as BTable).$el.querySelector(`.table tbody tr:nth-of-type(${index + 1})`);
    this.bulkOptionsMarginTop = (selectedRowEl as HTMLDivElement).offsetTop + (selectedRowEl as HTMLDivElement).offsetHeight - 20;
    if(this.bulkRows[index]) {
      this.bulkRows[index].value = !this.bulkRows[index].value;
    }
    
    if(item.value) {
      (this.$refs.accountingTable as BTable).selectRow(index);
    } else {
      (this.$refs.accountingTable as BTable).unselectRow(index);
    }

    this.showBulkOptions = !!(this.selectedRowsLength > 0)
  }

  clearTableSelection() {
    this.selectAllRowsBoolean = false;
    this.showBulkOptions = false;
    this.bulkRows.filter(el => el.value !== undefined).forEach(row => {
      row.value = false;
    });
  }

  closeDocument() {
    this.deselectRowEl();
    this.$store.commit('accounting/clearSelectedDocument');
    this.$store.commit('accounting/clearDocumentIndexes');
  }

  get numberOfPages() {
    return this.paginationInstance?.pageSizeNumberOfPages?.numberOfPages;
  }

  async prevDocument() {
    const prevDoc = this.$store.getters['accounting/prevDocument'];
    if(prevDoc) {
      this.selectRowDoc(prevDoc, this.prevDocumentIndex - this.accountingTableQuery.offset);
    } else {
      const prevDocumentIndex = _.clone(this.prevDocumentIndex);
      if (this.activeDocumentIndex === 0) {
        this.paginationInstance.$emit('change', this.numberOfPages);
        this.paginationInstance.$emit('changePage', this.numberOfPages);
      } else {
        const prevPageIndex = (this.paginationInstance.currentPage as number) - 1;
        this.paginationInstance.$emit('change', prevPageIndex);
        this.paginationInstance.$emit('changePage', prevPageIndex);
      }

      const doc = await this.$store.dispatch('accounting/getDocumentByOffset', {
        filtersQuery: this.filtersQuery,
        offset: prevDocumentIndex
      });

      if(doc) {
        this.selectRowDoc(doc, this.items.length - 1);
      }
    }
  }

  async nextDocument() {
    const nextDoc = this.$store.getters['accounting/nextDocument'];
    if(nextDoc) {
      this.selectRowDoc(nextDoc, this.nextDocumentIndex - this.accountingTableQuery.offset);
    } else {
      const nextDocumentIndex = _.clone(this.nextDocumentIndex);
      if(this.activeDocumentIndex === this.accountingTableQuery.limit) {
        this.paginationInstance.$emit('change', 1);
        this.paginationInstance.$emit('changePage', 1);
      } else {
        const nextPageIndex = (this.paginationInstance.currentPage as number) + 1;
        this.paginationInstance.$emit('change', nextPageIndex);
        this.paginationInstance.$emit('changePage', nextPageIndex);
      }

      const doc = await this.$store.dispatch('accounting/getDocumentByOffset', {
        filtersQuery: this.filtersQuery,
        offset: nextDocumentIndex,
      });

      if(doc) {
        this.selectRowDoc(doc, 0);
      }
    }
  }

  editDocument(rowData: any) {
    if(rowData) {
      this.selectRowDoc(rowData.item, rowData.index)
    } else {
      const document = rowData ? rowData.item : this.activeDocument;
      const id = (document) ? document.id : (this.activeDocument) ? this.activeDocument.id : false;
      if(id) {
        this.$router.push(`${this.$route.path}/${id}`);
      }
    }
  }

  downloadDocument() {
    if(this.activeDocument == undefined) return;
    this.downloadFileByUrl(this.activeDocument);
  }

  async sendDocument(document: any) {
    const doc = (document) ? document : (this.activeDocument) ? this.activeDocument : false;
    if(doc) {
      this.$store.commit(this.setTableBusyMutation, true);
      await this.$store.dispatch('accounting/sendDocumentToApproval', doc);

      if (!this.checkShouldSend(document)) {
        setTimeout(() => {
          this.$nextTick(() => {
            this.getItems();
          });
        }, 2000)
      } else {
        this.$nextTick(() => {
          this.getItems();
        });
      }

    }
  }

  getPaymentStatusClassName(status: string): string {
    let className = 'default';
    switch(status?.toLowerCase()) {
      case('unpaid'):
        className = 'danger';
        break;
      case('paid'):
        className = 'primary';
        break;
      case('scheduled'):
        className = 'success';
        break;
      case('partially_paid'):
        className = 'warning';
        break;
      default:
        className = 'default';
    }

    return className;
  }

  getDocumentStatusClassName(status: string): string {
    let className = 'default';
    switch(status?.toLowerCase()) {
      case('processed'):
        className = 'default';
        break;
      case('approved'):
        className = 'success';
        break;
      case('sent-to-approval'):
      case('booking'):
        className = 'warning';
        break;
      case('uploaded'):
      case('processing'):
      case('booked'):
        className = 'primary';
        break;
      case('failed'):
      case('rejected'):
      case('rejected-by-operator'):
      case('cancelled'):
      case('canceled'):
        className = 'danger';
        break;
      default:
        className = 'default';
    }

    return className;
  }

  statusText(status: string): string {
    switch(status) {
      case('approved'):
        return 'Approved';
      case('processing'):
        return 'Processing';
      case('uploaded'):
        return 'Uploaded';
      case('processed'):
        return 'Processed';
      case('sent-to-approval'):
        return 'Sent for approval';
      case('rejected'):
        return 'Rejected';
      case('failed'):
        return 'Failed';
      case('booked'):
        return 'Booked';
      case('booking'):
        return 'Booking';
      case('canceled'):
        return 'Canceled';
    }
    return status;
  }

  setQuery() {
    this.$store.commit('accounting/setDocumentsTableQuery', this.localTableQuery);
  }

  async getItems() {
    this.closeDocument();
    await this.$store.dispatch('accounting/getDocuments', this.filtersQuery);
    this.resetCheckboxes();
  }

  resetCheckboxes(): void {
    this.bulkRows = [];
    this.items.forEach((el, index) => {
      this.$set(this.bulkRows, index, { label: el.id, value: false });
    });
  }

  downloadFileByUrl(file: any) {
    Utils.downloadFileByUrl(`${BACKEND_BASE_URL}${file.pdfFilePath}`, file.fileName);
  }

  downloadMultipleDocuments() {
    let i: number = 0;
    const documentUrls = this.rowsSelected.map(el => {
      i++;
      const item = this.items.find(item => el.label == item.id);
      return { fileName: `Document-${i}-${item.fileName}`, path: item.pdfFilePath };
    });
    Utils.downloadZippedFilesByUrls(documentUrls);
  }

  deleteRow(item: any) {
    this.$emit('deleteRow', item);
  }

  enableBankAccountModal(item?: any) {
    this.paymentForSingleDocument = (item?.id) ? item.id : false;
    this.showBankAccountModal = true;
  }

  closeBankAccountModal() {
    this.showBankAccountModal = false;
    this.$v.$reset();
    this.selectedBankAccount = '';
  }

  async exportCsv() {
    this.isCsvExporting = true;

    try {
      let csvContent = await this.$store.dispatch('accounting/exportCsvByIds', { documentIds: this.rowsSelected.map((el: any) => el.label) });
      csvContent = csvContent.replace(/[\r]+/g, '').replace(';', ' ').replace(/&nbsp;/g, '').replace(/"[^"]*(?:""[^"]*)*"/g, function (m: any) { return m.replace(/\n/g, ''); })
      const universalBOM: string = '\uFEFF';
      let data = `data:text/csv;charset=utf-8,${encodeURIComponent(universalBOM + csvContent)}`;
      const link = document.createElement('a');
      link.setAttribute('href', data)
      link.setAttribute('download', `documents.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      const errorMessage = this.$options.filters.errorHandler(error);
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: this.$t(errorMessage).toString()
      });
    }

    this.isCsvExporting = false;
  }

  async exportCsvByFilters(queryString: string | null) {
    this.isCsvExporting = true;

    try {
      let csvContent = await this.$store.dispatch('accounting/exportCsvByFilters', queryString);
      csvContent = csvContent.replace(/[\r]+/g, '').replace(';', ' ').replace(/&nbsp;/g, '').replace(/"[^"]*(?:""[^"]*)*"/g, function (m: any) { return m.replace(/\n/g, ''); })
      const universalBOM: string = '\uFEFF';
      let data = `data:text/csv;charset=utf-8,${encodeURIComponent(universalBOM + csvContent)}`;
      const link = document.createElement('a');
      link.setAttribute('href', data)
      link.setAttribute('download', `documents.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();

    } catch (error) {
      const errorMessage = this.$options.filters.errorHandler(error);
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: this.$t(errorMessage).toString()
      });
    }

    this.isCsvExporting = false;
  }


  async generatePaymentPackage() {
    let selectedRowsIds = this.bulkRows.filter(el => el.value).map(el => el.label);

    if(this.paymentForSingleDocument) {
      selectedRowsIds = [this.paymentForSingleDocument];
    }

    this.$v.$touch();

    if(!this.$v.$error) {
      this.isPaymentPackageLoading = true
      const packageName = this.customPackageName ? this.customPackageName : this.defaultPaymentPackageName;
      const paymentDate = this.useCustomPaymentDate ? `${this.customPaymentDate} 00:00:00` : null;

      if(selectedRowsIds.length) {
        try {
          await this.$store.dispatch('accounting/generatePackage', { ids: selectedRowsIds, bankAccount: this.selectedBankAccount, bankAccountType: this.selectedBankType, paymentDate: paymentDate, packageName: packageName, isSepa: this.isSepa });
          this.closeBankAccountModal();
          this.onFiltersChange(this.filtersQuery);
        } catch(error) {
          const errorMessage = this.$options.filters.errorHandler(error);
          this.$notify({
            duration: 2500,
            type: 'error',
            title: 'Error',
            text: this.$t(errorMessage).toString()
          });
        }
      }

      this.isPaymentPackageLoading = false
    }
  }

  async bulkDocumentSendToApproval() {
    const selectedRowsIds = this.bulkRows.filter(el => el.value).map(el => el.label);

    try {
      const resp = await this.$store.dispatch('accounting/bulkGetDocuments', selectedRowsIds)
      const documents = resp.map((el: any) => el.data)
      const promises: Array<any> = [];
      
      documents.forEach((doc: any) => {
        promises.push(this.$store.dispatch('accounting/sendDocumentToApproval', doc));
      })

      this.$store.commit(this.setTableBusyMutation, true);
      await Promise.all(promises);

      setTimeout(() => {
        this.onFiltersChange(this.filtersQuery);
      }, 2000)
      
    } catch(e) {
      const errorMessage = this.$options.filters.errorHandler(e)
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: errorMessage
      })
    }
  }

  async bulkDocumentApproval() {
    const selectedRowsIds = this.bulkRows.filter(el => el.value).map(el => el.label);
    await this.$store.dispatch('accounting/setApproval', selectedRowsIds);

    this.onFiltersChange(this.filtersQuery);
  }

  async bulkDocumentRejection() {
    if(this.rejectionNote) {
      this.isRejectionModalLoading = true;
      const selectedRowsIds = this.bulkRows.filter(el => el.value).map(el => el.label);
      await this.$store.dispatch('accounting/setReject', { ids: selectedRowsIds, note: this.rejectionNote });
      this.isRejectionModalLoading = false;
      this.closeRejectionModal();
      this.onFiltersChange(this.filtersQuery);
    } else {
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Validation error',
        text: 'You must enter the reason of document rejection'
      });
    }
  }

  async bulkDocumentDeletion() {
    const selectedRowsIds = this.bulkRows.filter(el => el.value).map(el => el.label);
    this.$emit('bulkDelete', selectedRowsIds);
  }

  async bulkDocumentBook(ids: string[] | null, key: string) {
    if(key === 'book-readonly') return;

    if(ids) {
      this.$emit('book', ids);
    } else {
      const selectedRowsIds = this.bulkRows.filter(el => el.value).map(el => el.label);
      this.$emit('book', selectedRowsIds);
    }
  }

  get onlyWaitingForApproval() {
    const selectedRowsIds = this.bulkRows.filter(el => el.value).map(el => el.label);
    let onlyWaitingForApproval = true;

    if(selectedRowsIds.length) {
      selectedRowsIds.forEach((id: any) => {
        const item = this.items.find(el => el.id == id);
        if(item && !item.actions.includes('approve')) {
          onlyWaitingForApproval = false;
        }
      });

      return onlyWaitingForApproval;
    }

    return false;
  }

  get showBulkSendToApproval() {
    const selectedRowsIds = this.bulkRows.filter(el => el.value).map(el => el.label);
    let showBulkSendToApproval = true;

    if (selectedRowsIds.length) {
      selectedRowsIds.forEach((id: any) => {
        const item = this.items.find(el => el.id == id);
        if (!(item && item.status === 'processed' && !Utils.shouldSend(item, this.contextData, this.activeUserData))) {
          showBulkSendToApproval = false;
        }
      });

      return showBulkSendToApproval;
    }

    return false;
  }

  get onlyWaitingForRejection() {
    const selectedRowsIds = this.bulkRows.filter(el => el.value).map(el => el.label);
    let onlyWaitingForRejection = true;

    if(selectedRowsIds.length) {
      selectedRowsIds.forEach((id: any) => {
        const item = this.items.find(el => el.id == id);
        if(item && !item.actions.includes('reject')) {
          onlyWaitingForRejection = false;
        }
      });

      return onlyWaitingForRejection;
    }

    return false;
  }

  get onlyDeletableDocuments() {
    const selectedRowsIds = this.bulkRows.filter(el => el.value).map(el => el.label);
    let onlyWaitingForRejection = true;

    if(selectedRowsIds.length) {
      selectedRowsIds.forEach((id: any) => {
        const item = this.items.find(el => el.id == id);
        if(item && !item.actions.includes('delete')) {
          onlyWaitingForRejection = false;
        }
      });

      return onlyWaitingForRejection;
    }

    return false;
  }

  get onlyAccountingDateEditableDocuments() {
    const selectedRowsIds = this.bulkRows.filter(el => el.value).map(el => el.label);
    let onlyWaitingForRejection = true;

    if(selectedRowsIds.length) {
      selectedRowsIds.forEach((id: any) => {
        const item = this.items.find(el => el.id == id);
        if(item && !item.actions.includes('edit-accounting-date')) {
          onlyWaitingForRejection = false;
        }
      });

      return onlyWaitingForRejection;
    }

    return false;
  }

  get onlyBookableDocuments() {
    const selectedRowsIds = this.bulkRows.filter(el => el.value).map(el => el.label);
    let onlyWaitingForRejection = true;

    if(selectedRowsIds.length) {
      selectedRowsIds.forEach((id: any) => {
        const item = this.items.find(el => el.id == id);
        if(item && !item.actions.includes('book')) {
          onlyWaitingForRejection = false;
        }
      });

      return onlyWaitingForRejection;
    }

    return false;
  }

  get filtersQuery() {
    return this.$store.getters['accounting/getDocumentsTableFiltersQuery'];
  }

  get selectedDocument() {
    return this.$store.getters['accounting/getSelectedDocument'];
  }

  get rowsSelected() {
    const selectedRows = this.bulkRows.filter(el => el.value);

    return selectedRows;
  }

  get selectedRowsLength() {
    const selectedRows = this.bulkRows.filter(el => el.value);

    return selectedRows?.length;
  }

  get showDocument() {
    return this.selectedDocument;
  }

  get activeDocument() {
    const item = this.items.find(row => {
      return `${BACKEND_BASE_URL}${row.pdfFilePath}` == this.selectedDocument;
    });

    return item;
  }

  get activeDocumentIndex() {
    return this.$store.getters['accounting/activeDocumentIndex'];
  }

  get nextDocumentIndex() {
    return this.$store.getters['accounting/nextDocumentIndex'];
  }

  get prevDocumentIndex() {
    return this.$store.getters['accounting/prevDocumentIndex'];
  }

  get isProcessing() {
    const items = this.items.filter(item => item.status == 'uploaded' || item.status == 'processing');

    return (items.length) ? true : false;
  }

  get totals() {
    let totals: any = {};

    this.items.forEach((item: any) => {
      if(item.currency) {
        if(totals[item.currency]) {
          totals[item.currency] = item.grossAmount ? math.number(math.add(math.bignumber(totals[item.currency]), math.bignumber(item.grossAmount))) : math.number(math.add(math.bignumber(totals[item.currency]), 0));
        } else {
          totals[item.currency] = item.grossAmount ? math.bignumber(math.bignumber(item.grossAmount)) : 0;
        }
      }
    })

    return totals;
  }

  get selectedTotals() {
    let totals: any = {};
    const selectedRows = this.rowsSelected.map(el => {
      el = this.items.find(row => el.label == row.id);
      return el;
    });

    selectedRows.forEach((item: any) => {
      if (item.currency) {
        if (totals[item.currency]) {
          totals[item.currency] = item.grossAmount ? math.number(math.add(math.bignumber(totals[item.currency]), math.bignumber(item.grossAmount))) : math.number(math.add(math.bignumber(totals[item.currency]), 0));
        } else {
          totals[item.currency] = item.grossAmount ? math.bignumber(math.bignumber(item.grossAmount)) : 0;
        }
      }
    })

    return totals;
  }

  get onlyPaymentPackages(): boolean {
    const selectedRowsIds = this.bulkRows.filter(el => el.value).map(el => el.label);
    let onlyPaymentPackages = true;

    if(selectedRowsIds.length) {
      selectedRowsIds.forEach((id: any) => {
        const item = this.items.find(el => el.id == id);
        if(item && !item.actions.includes('generate-xml-payment')) {
          onlyPaymentPackages = false;
        }
      });

      return onlyPaymentPackages;
    }

    return false;
  }

  get activeUserData() {
    return this.$store.getters['genprox/activeUserData'];
  }

  get bankAccounts() {
    return this.$store.getters['accounting/getBankAccounts'];
  }

  get formattedBankAccounts() {
    return this.bankAccounts.map((el: any) => {
      return {
        label: `${el.name} | ${el.account}`,
        value: el.account
      }
    })
  }

  get selectedBankName(): string {
    const selectedBank = this.bankAccounts.find((el: any) => el.account == this.selectedBankAccount);

    if(selectedBank) {
      return selectedBank.name;
    }

    return '';
  }

  get selectedBankCurrency(): string {
    const selectedBank = this.bankAccounts.find((el: any) => el.account == this.selectedBankAccount);

    if(selectedBank) {
      return selectedBank.currency;
    }

    return '';
  }

  get selectedBankType(): string {
    const selectedBank = this.bankAccounts.find((el: any) => el.account == this.selectedBankAccount);

    if (selectedBank) {
      return selectedBank?.type;
    }

    return '';
  }

  validations () {
    return  {
      selectedBankAccount: { required },
      customPaymentDate: { required: requiredIf(() => this.useCustomPaymentDate) }
    }
  }

  async beforeMount() {
    await this.getItems();
    this.$store.dispatch('accounting/getBankAccounts');
    this.onMounted();
    this.setBorderlessTds();
  }

  addPagination(): void {
    const ComponentClass = Vue.extend(SygniPagination);
    this.paginationInstance = new ComponentClass<SygniPagination>();

    const currentPage = this.accountingTableQuery.offset / this.accountingTableQuery.limit + 1;

    this.paginationInstance.showPageNumberOptions = this.showPageNumberOptions;
    this.paginationInstance.perPage = this.tableData.perPage;
    this.paginationInstance.totalRows = this.tableData.totalCount;
    this.paginationInstance.currentPage = currentPage;
    this.paginationInstance.$mount();
    this.paginationInstance.$on('change', (currentPage: number) => {
      this.$store.commit(this.setTableBusyMutation, true);
      this.localTableQuery = new TableQuery().setQuery(this.tableData.query);
      this.localTableQuery.limit = this.tableData.perPage;
      this.localTableQuery.offset = this.tableData.perPage * (currentPage - 1);
      this.setQuery();
      this.getItems();
      this.$scrollTo(((this.$refs.accountingTable as AccountingTable).$el as HTMLElement));
    });
    this.paginationInstance.$on('changePerPageOption', (perPage: number) => {
      if(this.tablePerPageMutation) this.$store.commit(this.tablePerPageMutation, perPage);
      this.$store.commit(this.setTableBusyMutation, true);
      this.localTableQuery = new TableQuery().setQuery(this.tableData.query);
      this.localTableQuery.limit = this.tableData.perPage;
      this.localTableQuery.offset = 0;
      this.setQuery();
      this.getItems();
      this.changePerPageOption();
    });

    this.$nextTick(() => {
      this.paginationInstance.$emit('changePage', currentPage)
    })

    const tableElement: Element = this.$el.querySelector('.sygni-b-table') as Element;

    const isAccountingTable = this.$el.classList.contains('accounting-table');
    const isWhistleblowerTable = this.$el.classList.contains('whistleblower-table');
    const isPortfolioTable = this.$el.classList.contains('portfolio-table');

    if(isAccountingTable) {
      this.$el.querySelector('.accounting-table__inner').insertBefore(this.paginationInstance.$el as Node, tableElement.nextSibling);
    } else if(isWhistleblowerTable) {
      this.$el.querySelector('.whistleblower-table__inner').insertBefore(this.paginationInstance.$el as Node, tableElement.nextSibling);
    } else if(isPortfolioTable) {
      this.$el.querySelector('.portfolio-table__inner').insertBefore(this.paginationInstance.$el as Node, tableElement.nextSibling);
    } else {
      this.$el.insertBefore(this.paginationInstance.$el as Node, tableElement.nextSibling);
    }
  }

  @Watch('selectAllRowsBoolean') onSelectAllRowsBooleanChange(): void {
    const selectedRowEl = (this.$refs.accountingTable as BTable).$el.querySelector(`.table tbody tr:nth-of-type(1)`);
    this.bulkOptionsMarginTop = (selectedRowEl as HTMLDivElement).offsetTop + (selectedRowEl as HTMLDivElement).offsetHeight - 20;

    this.bulkRows.filter(el => el.value !== undefined).forEach(row => {
      row.value = this.selectAllRowsBoolean;
    });

    this.showBulkOptions = this.selectAllRowsBoolean;
  }

  @Watch('rowsSelected') onRowsSelectedChange(): void {
    const selectedRowsIds = this.bulkRows.filter(el => el.value).map(el => el.label);
    let onlySamePaymentStatuses = true;
    let lastPaymentStatus = '';

    if(selectedRowsIds.length) {
      selectedRowsIds.forEach((id: any, index: number) => {
        const item = this.items.find(el => el.id == id);
        if(index != 0) {
          if(item.paymentStatus !== lastPaymentStatus) {
            onlySamePaymentStatuses = false;
          }
        }
        lastPaymentStatus = item.paymentStatus;
      });
    }

    this.bulkPaymentStatusPlaceholder = onlySamePaymentStatuses ? this.$options.filters.capitalizeFirstLetter(lastPaymentStatus) : 'Select option';
    this.bulkPaymentStatus = '';
  }

  @Watch('bulkPaymentStatus') onBulkPaymentStatusChange(): void {
    if(this.bulkPaymentStatus) {
      this.paymentStatusBulkUpdate();
    }
  }

  @Watch('accountingDate') onAccountingDateChange() {
    if(this.accountingDate) {
      this.accountingDateBulkUpdate();
    }
  }

  async paymentStatusBulkUpdate() {
    const selectedRowsIds = this.bulkRows.filter(el => el.value).map(el => el.label);
    const payload = { documentIds: selectedRowsIds, status: this.bulkPaymentStatus };
    this.isPaymentStatusLoading = true;

    try {
      await this.$store.dispatch('accounting/bulkUpdatePaymentStatuses', payload);
      selectedRowsIds.forEach((id: any) => {
        const item = this.items.find(el => el.id == id);
        item.paymentStatus = payload.status;
      });
      this.isPaymentStatusLoading = false;
      this.$notify({
        duration: 2500,
        type: 'success',
        title: 'Success',
        text: 'Payment statuses updated.'
      });
    } catch(err) {
      const errorMessage = this.$options.filters.errorHandler(err);
      this.isPaymentStatusLoading = false;
      this.$notify({
        duration: 3000,
        type: 'error',
        title: 'Error',
        text: errorMessage
      })
    }
  }

  async accountingDateBulkUpdate() {
    const selectedRowsIds = this.bulkRows.filter(el => el.value).map(el => el.label);
    const payload = { documentIds: selectedRowsIds, accountingDate: this.accountingDate };
    this.isAccountingDateLoading = true;

    try {
      await this.$store.dispatch('accounting/bulkUpdateAccountingDate', payload);
      selectedRowsIds.forEach((id: any) => {
        const item = this.items.find(el => el.id == id);
        item.documentReceiptDate = payload.accountingDate;
      });
      this.$notify({
        duration: 2500,
        type: 'success',
        title: 'Success',
        text: 'Accounting date updated.'
      });
    } catch (err) {
      const errorMessage = this.$options.filters.errorHandler(err);
      this.$notify({
        duration: 3000,
        type: 'error',
        title: 'Error',
        text: errorMessage
      })
    }

    this.isAccountingDateLoading = false;
  }

  @Watch('items') onItemsChange(): void {
    if (this.refresh) {
      clearTimeout(this.refresh);
      this.refresh = undefined;
    }

    if(this.isProcessing) {
      this.$store.commit('accounting/setDocumentsTableBusy', true);
      this.refresh = setTimeout(() => {
        this.onFiltersChange(this.filtersQuery);
      }, 3000);
    }
  }
}

