var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sygni-flag-select"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm.label))]), _c('multiselect', _vm._g(_vm._b({
    ref: "multiselect",
    attrs: {
      "options": _vm.options,
      "value": _vm.optionValue,
      "label": _vm.optionsLabel,
      "track-by": "label",
      "maxHeight": 205,
      "multiple": false,
      "limit": 3,
      "showLabels": false
    },
    on: {
      "input": _vm.handleInput,
      "search-change": _vm.handleSearch
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function fn(_ref) {
        var values = _ref.values;
        return [_c('div', {
          class: ['sygni-flag-select__container', values.length == 0 ? 'hide' : '']
        }, _vm._l(values, function (flag) {
          return _c('sygni-flag', {
            key: flag.label,
            attrs: {
              "color": flag.color
            }
          });
        }), 1)];
      }
    }, {
      key: "tag",
      fn: function fn(props) {
        return [_vm._v(_vm._s(props))];
      }
    }, {
      key: "option",
      fn: function fn(_ref2) {
        var option = _ref2.option;
        return [_c('sygni-flag', {
          attrs: {
            "color": option.color
          }
        }), _c('div', {
          staticClass: "text"
        }, [_vm._v(" " + _vm._s(option.label) + " ")])];
      }
    }])
  }, 'multiselect', Object.assign({}, _vm.removeKey(Object.assign({}, _vm.$props), 'value'), _vm.removeKey(Object.assign({}, _vm.$attrs), 'value')), false), _vm.removeKey(Object.assign({}, _vm.$listeners), 'input')), [!_vm.isFiltered && _vm.selectAll ? _c('template', {
    slot: "beforeList"
  }, [_c('div', {
    staticClass: "sygni-flag-select__toggle-option"
  }, [_c('sygni-link-button', {
    staticClass: "text-danger",
    attrs: {
      "type": "simple"
    },
    on: {
      "click": _vm.toggleAll
    }
  }, [_vm._v(_vm._s(_vm.toggleAllText))])], 1)]) : _vm._e()], 2), _c('sygni-input-error', {
    attrs: {
      "validation": _vm.validation
    }
  }, [_vm._t("error")], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }